<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 积分规则配置
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			v-loading="tableLoading">
			<el-table-column prop="condition_name" label="奖励条件" align="center"></el-table-column>
			<el-table-column prop="score" label="奖励积分" align="center"></el-table-column>
			<el-table-column prop="created_at" label="更新时间" align="center"></el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="handle(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog title="编辑积分" v-model="editVisible" width="30%">
			<el-form ref="form" :model="editQuery" label-width="80px">
				<el-form-item label="奖励积分">
					<el-input v-model="editQuery.score"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="editVisible = false">取 消</el-button>
					<el-button type="primary" @click="save">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		// watch,
		reactive,
		ref
	} from 'vue'
	import {
		// ElMessageBox,
		ElMessage
	} from 'element-plus'
	import request from '@/utils/request.js'
	export default {
		setup() {
			let tableLoading = ref(false),
			pageTotal = ref(0),
			tableData = ref([]),
			query = reactive({
				page: 1,
				limit: 15
			}),
			editQuery = reactive({
				id: '',
				type: 0,
				score: ''
			}),
			editVisible = ref(false)
			const getList = ()=> {
				tableLoading.value = true
				request({
					url: '/admin/point/getRuleList',
					method: 'get',
					params: {
						...query
					}
				}).then((res) => {
					tableLoading.value = false;
					if (res.code == 200) {
						tableData.value = res.data.data;
						pageTotal.value = res.data.total;
					}
				}).catch(() => {
					tableLoading.value = false;
				});
			}
			const handlePageChange = (val)=> {
				query.page = val
				getList()
			}
			const handle = (item)=> {
				editQuery.id = item.id
				editQuery.score = item.score
				editVisible.value = true
			}
			const save = ()=> {
				request({
					url: '/admin/point/editRule',
					method: 'post',
					data: {
						...editQuery
					}
				}).then((res) => {
					if (res.code == 200) {
						getList()
						ElMessage.success("编辑成功")
						editVisible.value = false
					}else {
						ElMessage.error(res.msg)
					}
				}).catch(() => {
				});
			}
			getList()
			return {
				getList,
				tableData,
				pageTotal,
				handlePageChange,
				query,
				editQuery,
				editVisible,
				handle,
				tableLoading,
				save
			}
		}
	}
</script>

<style>
</style>
